// REMOTE
(() => {
  const stringToNode = (htmlString, { remoteId } = {}) => {
    let scriptEl;
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // div.firstChild trick doesn't work for <script> elements
    // script has to be created strictly using document.createElement
    if (div.firstChild && div.firstChild.tagName === 'SCRIPT') {
      scriptEl = document.createElement('script');
      if (div.firstChild.innerHTML) {
        scriptEl.innerHTML = div.firstChild.innerHTML;
      } else if (div.firstChild.src) {
        scriptEl.src = div.firstChild.src;
        scriptEl.async = div.firstChild.async;
      }
      return scriptEl;
    }
    if (div.firstChild) {
      if (remoteId) {
        div.firstChild.setAttribute('data-remote-id', remoteId);
      }
      return div.firstChild;
    }

    return null;
  };
  const injectStyles = (styles) => {
    const styleNodes = document.querySelectorAll('style');
    let existingStyles = [];
    Array.prototype.slice.call(styleNodes).map((n) => {
      existingStyles.push(n.innerText);
    });

    styles.map((s) => {
      if (existingStyles.indexOf(s.innerText) === -1) {
        document.head.appendChild(s);
      }
    });
  };

  const injectLinks = (links) => {
    const linkNodes = document.querySelectorAll('link');
    let existingLinks = [];
    Array.prototype.slice.call(linkNodes).map((n) => {
      existingLinks.push(n.href);
    });

    links.map((l) => {
      if (existingLinks.indexOf(l.href) === -1) {
        if (l.getAttribute('rel') === 'stylesheet') {
          document.body.appendChild(l);
        } else {
          document.head.appendChild(l);
        }
      }
    });
  };

  const injectScripts = (scripts) => {
    const scriptNodes = document.querySelectorAll('script');
    let existingScripts = [];
    let existingInlineScripts = [];
    Array.prototype.slice.call(scriptNodes).map((n) => {
      if (n.src) {
        existingScripts.push(n.src);
      } else {
        existingInlineScripts.push(n.innerText);
      }
    });

    scripts.map((s) => {
      if (s.src) {
        if (existingScripts.indexOf(s.src) === -1) {
          document.body.appendChild(s);
        }
      }
      if (s.innerText) {
        if (existingInlineScripts.indexOf(s.innerText) === -1) {
          document.body.appendChild(s);
        }
      }
    });
  };

  const buildAssetsMap = (headAppends, bodyAppends) => {
    let assets = {
      styles: [],
      links: [],
      scripts: [],
    };

    let i;
    // simple naive assets parsing
    for (i = 0; i < headAppends.length; i += 1) {
      if (headAppends[i].substring(0, 6) === '<style') {
        assets.styles.push(stringToNode(headAppends[i]));
      } else if (headAppends[i].substring(0, 7) === '<script') {
        assets.scripts.push(stringToNode(headAppends[i]));
      } else if (headAppends[i].substring(0, 5) === '<link') {
        assets.links.push(stringToNode(headAppends[i]));
      }
    }

    for (i = 0; i < bodyAppends.length; i += 1) {
      if (bodyAppends[i].substring(0, 7) === '<script') {
        assets.scripts.push(stringToNode(bodyAppends[i]));
      }
      if (bodyAppends[i].substring(0, 5) === '<link') {
        assets.links.push(stringToNode(bodyAppends[i]));
      }
    }
    return assets;
  };

  const evaluateFallbackIfAvailable = (config) => {
    let fallbackConfig;
    if (config.fallback) {
      fallbackConfig = config.fallback;
      fallbackConfig.id = config.id;
      evaluateAsync(fallbackConfig);
    }
  };

  const trackRemoteError = (err, element, config) => {
    if (typeof window !== 'undefined' && window && window.TrackJS) {
      window.TrackJS.addMetadata('remote_vanilla_error', err);
      window.TrackJS.addMetadata('remote_vanilla_error_element', element);
      window.TrackJS.addMetadata('component_error_type', 'ExceptionError');
      window.TrackJS.console.error('component_error', { err, element, config });
      window.TrackJS.removeMetadata('remote_vanilla_error');
      window.TrackJS.removeMetadata('remote_vanilla_error_element');
      window.TrackJS.removeMetadata('component_error_type');
    }
  };

  const evaluateAsync = (config) => {
    const id = config.id;
    const url = config.fetch;
    let assets;
    let xhr;
    let jsonResponse;

    const el = document.querySelector(
      '.tc_remote[data-remote-id="' + id + '"]',
    );
    if (!el) {
      return;
    }

    if (!url || url === '') {
      trackRemoteError(new Error('Remote content URL was not set'), el, config);
      return;
    }

    el.classList.add('tc_remote--loading');

    xhr = new XMLHttpRequest();
    xhr.onload = function _load() {
      if (this.status === 200) {
        try {
          jsonResponse = JSON.parse(xhr.responseText); // fix for IE, it doesn't support JSON response type
        } catch (e) {
          evaluateFallbackIfAvailable(config);
        }

        // Exit early if body is empty
        if (!jsonResponse.body || jsonResponse.body === '') {
          return evaluateFallbackIfAvailable(config);
        }

        assets = buildAssetsMap(
          jsonResponse.headAppend,
          jsonResponse.bodyAppend,
        );
        injectStyles(assets.styles);
        injectScripts(assets.scripts);
        injectLinks(assets.links);

        try {
          el.parentNode.replaceChild(
            stringToNode(jsonResponse.body, { remoteId: id }),
            el,
          );
        } catch (e) {
          trackRemoteError(e, el, config);
        }
      } else {
        evaluateFallbackIfAvailable(config);
      }
    };
    xhr.ontimeout = () => {
      el.classList.remove('tc_remote--loading');
      el.classList.add('tc_remote--timed-out');
    };

    xhr.open('GET', url);
    xhr.send();
  };

  const initRemoteElements = () => {
    const configs = window.asyncComponents;
    let i;
    for (i = 0; i < configs.length; i += 1) {
      evaluateAsync(configs[i]);
    }
  };

  const domready = () => {
    try {
      initRemoteElements();
    } catch (error) {
      if (window.TrackJS) {
        window.TrackJS.console.error('Failed to load remote elements', {
          error,
        });
      }
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', domready);
  } else {
    domready();
  }
})();
